<template>
  <div class="relative flex w-full justify-between overflow-hidden">
    <IconsUp
      v-if="showLeftArrow"
      :class="[{ 'opacity-100': showLeftArrow }, iconColor]"
      class="absolute -left-1 z-10 size-tab-arrow -rotate-90 stroke-black stroke-2 opacity-0 transition-opacity"
      @click="scrollTabs(-200)"
    />
    <IconsUp
      v-if="showRightArrow"
      :class="[{ 'opacity-100': showRightArrow }, iconColor]"
      class="absolute -right-1 z-10 size-tab-arrow rotate-90 stroke-black stroke-2 opacity-0 transition-opacity"
      @click="scrollTabs(200)"
    />
    <div
      ref="wrapper"
      class="flex overflow-auto whitespace-nowrap md:scrollbar md:scrollbar-thin md:scrollbar-track-gray-100 md:scrollbar-thumb-teal/50"
      :class="classes"
      @scroll="handleScroll"
    >
      <slot name="scrollableContent" />
    </div>
    <slot name="additionalContent" />
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
const props = defineProps<{
  classes: string;
  color: string;
}>();

const showLeftArrow: Ref<boolean> = ref(false);
const showRightArrow: Ref<boolean> = ref(false);
const wrapper = ref();

function scrollTabs(value: number): void {
  if (wrapper.value)
    wrapper.value.scrollLeft = wrapper.value.scrollLeft + value;
}

function handleScroll(): void {
  if (wrapper.value) {
    const position = wrapper.value.scrollLeft;
    const width = wrapper.value.scrollWidth;

    showLeftArrow.value = position > 10;
    showRightArrow.value = width - position - window.innerWidth > 10;
  }
}

const colorClasses = {
  blue: "fill-g7-blue",
  white: "fill-white",
};
const iconColor = computed(() => {
  return `${colorClasses[props.color]}`;
});

onMounted(() => {
  if (process.client) {
    handleScroll();
  }
});
</script>
